import classNames from 'classnames/bind';
import { motion, useReducedMotion, useScroll, useTransform } from 'framer-motion';
import React from 'react';

import { HomeTiles } from './HomeTiles/HomeTiles';
import { Image } from '@components/Image/Image';
import { Container } from '@components/Layout/Container/Container';
import { LogosRow } from '@components/LogosRow/LogosRow';
import { Padding } from '@components/Padding/Padding';
import { Text } from '@components/Text/Text';
import { useIsMounted } from '@hooks/useIsMounted';

import styles from './HomeHeader.module.scss';
import globeImage from './globe.png';
import eslLogo from '@logos/Black/esl.svg';
import avastLogo from '@logos/Colored/avast.svg';
import banijayLogo from '@logos/Colored/banijay.svg';
import rakutenLogo from '@logos/Colored/rakuten.svg';
import starzLogo from '@logos/Colored/starz.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

const cx = classNames.bind(styles);

export const mainLogos = [
  { src: starzLogo, name: 'Starz', width: 85, height: 25 },
  { src: rakutenLogo, name: 'Rakuten', width: 85, height: 27 },
  { src: udemyLogo, name: 'Udemy', width: 78, height: 29 },
  { src: eslLogo, name: 'ESL', width: 67, height: 21 },
  { src: avastLogo, name: 'Avast', width: 88, height: 28 },
  { src: banijayLogo, name: 'Banijay', width: 84, height: 24 },
];

export const HomeHeader: React.FC = () => {
  const ref = React.useRef<HTMLElement>(null);

  const shouldReduceMotion = useReducedMotion();
  const isMounted = useIsMounted();
  const { scrollY } = useScroll({ target: ref });

  const y = useTransform(scrollY, [0, 1000], [0, 250]);
  const opacity = useTransform(scrollY, [0, 1000], [1, 0]);

  const withAnimation = isMounted && !shouldReduceMotion;

  return (
    <header ref={ref} className={styles.wrapper}>
      <Container className={styles.container}>
        <Padding size={{ top: 170, bottom: 60, x: 0 }}>
          <div className={cx('titleWrapper', 'mb-8')}>
            <Text className="mb-2" color="gray900" display="block" size={56} tag="h1" weight="bold">
              Big enough to handle your traffic.
            </Text>
            <Text color="gray500" display="block" opacity={70} size={34} tag="p">
              Never big enough to stop putting clients first.
            </Text>
          </div>
          <div className="mb-8">
            <HomeTiles />
          </div>

          <LogosRow logos={mainLogos} note="Trusted by" />

          <div className={styles.globe}>
            <motion.div style={withAnimation ? { y, opacity } : undefined}>
              <Image alt="" height={985} src={globeImage} width={993} priority />
            </motion.div>
          </div>
        </Padding>
      </Container>
    </header>
  );
};
