import classNames from 'classnames/bind';
import React from 'react';

import styles from './Tiles.module.scss';

interface Props {
  higherContrast?: boolean;
  className?: string;
  tabletVariant?: '2-columns' | '3-columns';
  children: React.ReactNode[];
}

const cx = classNames.bind(styles);

export const Tiles: React.FC<Props> = ({
  higherContrast,
  tabletVariant = '3-columns',
  className,
  children,
}) => (
  <ul className={cx('wrapper', className, `layout-${tabletVariant}`, { higherContrast })}>
    {children.map((item, index) => (
      <li key={index} className={styles.item}>
        {item}
      </li>
    ))}
  </ul>
);
