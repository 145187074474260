import classNames from 'classnames/bind';
import React from 'react';

import styles from './ListBullet.module.scss';

export type BulletSize = 'auto' | number;
export type BulletShape = 'square' | 'circle';
export type BulletType = 'bordered' | 'filled';
export type BulletColor = 'black' | 'blue' | 'yellow';

interface Props {
  size: BulletSize;
  shape: BulletShape;
  type: BulletType;
  color: BulletColor;
}

const cx = classNames.bind(styles);

export const ListBullet: React.FC<Props> = ({ size, type, color, shape }) => (
  <div
    className={cx(type, shape, color)}
    style={{ width: size === 'auto' ? '0.3em' : size, height: size === 'auto' ? '0.3em' : size }}
  />
);
