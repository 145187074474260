import { AnimatePresence, motion, useReducedMotion } from 'framer-motion';
import React from 'react';

import { CoreFeatureItem } from './CoreFeatureItem/CoreFeatureItem';
import { Image, type IImage } from '@components/Image/Image';
import { Section } from '@components/Section/Section';
import { useIsMounted } from '@hooks/useIsMounted';
import { routes } from '@routes';

import styles from './CoreFeatures.module.scss';
import clientCentricImage from './client-centric.png';
import stabilityImage from './stability.png';
import videoDeliveryImage from './video-delivery.png';

const data: {
  title: string;
  text: string;
  list: readonly string[];
  button?: {
    text: string;
    href: string;
  };
  image: { src: IImage; width: number; height: number };
}[] = [
  {
    title: 'Client-centric at every step',
    text: 'From custom onboarding to detailed optimizations and continuous monitoring, we go above and beyond to meet and exceed your expectations.',
    list: [
      'Direct access to L2/L3 engineers through your preferred dedicated communication channel for fast, expert support.',
      'Client-specific customizations are included as part of our standard service.',
    ],
    image: { src: clientCentricImage, width: 475, height: 552 },
  },
  {
    title: 'Video delivery at our core',
    text: 'With 90% of our delivered data dedicated to video, high-quality streaming is where we thrive.',
    list: [
      'Tailored caching strategies optimized for both live and on-demand delivery.',
      'Scalable infrastructure ready to serve massive global audiences.',
      'Deep expertise in low-latency live streaming.',
    ],
    button: {
      text: 'Video delivery',
      href: routes.videoCdn,
    },
    image: { src: videoDeliveryImage, width: 475, height: 633 },
  },
  {
    title: 'Stability through cost efficiency',
    text: 'We prioritize cost efficiency for our business and clients – building lasting, sustainable partnerships.',
    list: [
      'Over a decade of profitability and steady growth.',
      'Reached annual revenue of $175M in 2024.',
      'Ranked among the top 5 largest CDNs by revenue.',
    ],
    button: {
      text: 'About us',
      href: routes.aboutUs,
    },
    image: { src: stabilityImage, width: 475, height: 566 },
  },
];

export const CoreFeatures: React.FC = () => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const shouldReduceMotion = useReducedMotion();
  const isMounted = useIsMounted();
  const [visibleItem, setVisibleItem] = React.useState<typeof data[number]['title']>(data[0].title);

  const withAnimation = isMounted && !shouldReduceMotion;

  const activeItem = data.find(({ title }) => title === visibleItem);

  React.useEffect(() => {
    const documentScroll = document.documentElement?.scrollTop ?? 0;
    const element = wrapperRef.current?.getBoundingClientRect();
    const wrapperScroll = element?.top ?? 0; // top relative to viewport
    const wrapperHeight = element?.height ?? 0;

    const startOfLastItem = documentScroll + wrapperScroll + wrapperHeight * 0.66;

    if (documentScroll > startOfLastItem) {
      setVisibleItem(data[data.length - 1].title);
    }
  }, []);

  return (
    <Section>
      <div ref={wrapperRef} className={styles.wrapper}>
        <div className={styles.content}>
          {data.map(({ title, text, list, button, image }) => (
            <CoreFeatureItem
              key={title}
              button={button}
              image={image}
              list={list}
              text={text}
              title={title}
              visibleItem={visibleItem}
              onVisible={() => {
                setVisibleItem(title);
              }}
            />
          ))}
        </div>
        <div className={styles.sticky} style={{ color: 'white' }}>
          <AnimatePresence mode="popLayout">
            {activeItem && (
              <motion.div
                key={activeItem.image.height}
                animate={withAnimation ? { opacity: 1 } : undefined}
                exit={withAnimation ? { opacity: 0 } : undefined}
                initial={
                  withAnimation
                    ? { opacity: activeItem.title === data[0].title ? 1 : 0 }
                    : undefined
                }
              >
                <Image
                  alt=""
                  height={activeItem.image.height}
                  src={activeItem.image.src}
                  width={activeItem.image.width}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </Section>
  );
};
