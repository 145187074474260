import React from 'react';

import Counter from '@components/Counter/Counter';
import { Image } from '@components/Image/Image';
import { Text } from '@components/Text/Text';

import styles from './ContinentStats.module.scss';
import mapImage from './map.png';

const data = [
  { value: 29, title: 'North America', left: 14, top: 30 },
  { value: 31, title: 'Europe', left: 48.5, top: 23.5 },
  { value: 21, title: 'Asia & Pacific', left: 70.5, top: 45 },
  { value: 13, title: 'South America', left: 24, top: 67 },
  { value: 6, title: 'Africa', left: 48.5, top: 53 },
];

export const ContinentStats: React.FC = () => (
  <div className={styles.wrapper}>
    <ul className={styles.stats}>
      {data.map(({ value, title, left, top }) => (
        <li key={title} className={styles.item} style={{ left: `${left}%`, top: `${top}%` }}>
          <Text
            className={styles.value}
            color="blue300"
            display="block"
            size={56}
            tag="p"
            weight="bold"
          >
            <Counter delay={0.2} from={0} to={value} />%
          </Text>
          <Text className={styles.name} color="gray500" size={16} tag="p">
            {title}
          </Text>
        </li>
      ))}
    </ul>
    <Image alt="" height={820} src={mapImage} width={1593} />
  </div>
);
