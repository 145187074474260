import React from 'react';

import { ReviewsCarousel } from '@components/ReviewsCarousel/ReviewsCarousel';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';

export const HomeReviews: React.FC = () => (
  <Section overflow="hidden" padding={{ top: 150, bottom: 150 }}>
    <Text
      align="center"
      className="mb-1"
      color="blue300"
      display="block"
      size={14}
      tag="strong"
      transform="uppercase"
      weight="medium"
    >
      Enough from us.
    </Text>
    <Text
      align="center"
      className="mb-7"
      color="gray900"
      display="block"
      size={44}
      tag="h2"
      weight="regular"
    >
      What others say about CDN77?
    </Text>

    <ReviewsCarousel />
  </Section>
);
