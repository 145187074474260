import { motion, useScroll, useTransform, useReducedMotion, useInView } from 'framer-motion';
import React from 'react';

import { Button } from '@components/Button/Button';
import { Image, type IImage } from '@components/Image/Image';
import { List } from '@components/List/List';
import { Text } from '@components/Text/Text';

import styles from './CoreFeatureItem.module.scss';

interface Props {
  title: string;
  text: string;
  list: readonly string[];
  button?: {
    text: string;
    href: string;
  };
  visibleItem: string;
  image: { src: IImage; width: number; height: number };
  onVisible: () => void;
}

export const CoreFeatureItem: React.FC<Props> = ({
  title,
  text,
  list,
  button,
  image,
  visibleItem,
  onVisible,
}) => {
  const ref = React.useRef(null);

  const shouldReduceMotion = useReducedMotion();

  const isInView = useInView(ref, { amount: 'all', once: false });
  const { scrollYProgress } = useScroll({
    target: ref,
    axis: 'y',
    offset: ['start start', 'start end'],
    // offset: scrollDirection === 'down' ? ['start end', 'end end'] : ['end start', 'end end'],
  });

  const value = useTransform(scrollYProgress, [0, 0.5, 1], [0.2, 1, 0.2]);

  React.useEffect(() => {
    if (isInView) {
      onVisible();
    }
  }, [isInView]);

  return (
    <motion.div
      className={styles.wrapper}
      style={shouldReduceMotion ? undefined : { opacity: visibleItem === title ? 1 : value }}
    >
      <div className={styles.content}>
        <div ref={ref} className={styles.refWrapper} />
        <Text className="mb-5" color="gray900" size={30} tag="h2" weight="bold">
          {title}
        </Text>
        <Text className="mb-5" size={18} tag="p">
          {text}
        </Text>
        <List
          gap={15}
          items={list}
          size={18}
          ulClassName={button !== undefined ? 'mb-6' : undefined}
        />

        {button && (
          <Button href={button.href} theme="button" variant="secondary">
            {button.text}
          </Button>
        )}
      </div>

      <Image
        alt=""
        className={styles.image}
        height={image.height}
        src={image.src}
        width={image.width}
      />
    </motion.div>
  );
};
