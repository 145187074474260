import { animate, motion, useInView, useReducedMotion } from 'framer-motion';
import React from 'react';

import { useIsMounted } from '@hooks/useIsMounted';

interface CounterProps {
  from: number;
  to: number;
  delay?: number;
}

const Counter: React.FC<CounterProps> = ({ from, to, delay }) => {
  const shouldReduceMotion = useReducedMotion();
  const isMounted = useIsMounted();

  const ref = React.useRef<HTMLParagraphElement | null>(null);
  const isInView = useInView(ref, { once: true });

  React.useEffect(() => {
    if (!isInView || shouldReduceMotion || !isMounted) return;

    const node = ref.current;
    if (!node) return;

    const controls = animate(from, to, {
      duration: 1,
      delay,
      onUpdate(value) {
        node.textContent = Math.round(value).toString();
      },
    });

    return () => controls.stop();
  }, [from, to, isInView, shouldReduceMotion, isMounted]);

  return <motion.span ref={ref}>{!isMounted || shouldReduceMotion ? to : undefined}</motion.span>;
};

export default Counter;
