import classNames from 'classnames/bind';
import React from 'react';

import { ContinentStats } from './ContinentStats/ContinentStats';
import { AnimatedPath } from '@components/AnimatedPath/AnimatedPath';
import { Icon } from '@components/Icon/Icon';
import { Container } from '@components/Layout/Container/Container';
import { Section } from '@components/Section/Section';
import { Text } from '@components/Text/Text';
import { Tiles } from '@components/Tiles/Tiles';
import { cdnStats } from '@siteData';

import styles from './NetworkStats.module.scss';
import CdnsIcon from '@icons/cdns.svg';
import GlobalNetworkIcon from '@icons/globalNetwork.svg';
import PeakIcon from '@icons/peak.svg';

const data = [
  { value: cdnStats.trafficPeak.total, title: 'daily traffic peaks', icon: <PeakIcon /> },
  {
    value: `${cdnStats.ispConnected.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}+`,
    title: 'ISPs connected directly',
    icon: <CdnsIcon />,
  },
  {
    value: `${cdnStats.locationsCount.fullPops + cdnStats.locationsCount.onDemand} PoPs`,
    title: 'across 6 continents',
    icon: <GlobalNetworkIcon />,
  },
];

const cx = classNames.bind(styles);

export const NetworkStats: React.FC = () => (
  <Section
    className={styles.wrapper}
    padding={{ top: 120, bottom: 20 }}
    theme="tiles"
    withContainer={false}
  >
    <Container>
      <Text
        align="center"
        className="mb-5"
        color="gray900"
        display="block"
        maxWidth={600}
        size={44}
        tag="h2"
        isCentered
      >
        <b>Deliver content</b> to every corner of the Earth
      </Text>
      <Text
        align="center"
        className="mb-8"
        color="gray500"
        display="block"
        maxWidth={730}
        size={18}
        tag="p"
        isCentered
      >
        A network built with strategically placed <strong>Super PoPs</strong> and{' '}
        <strong>ISP-embedded cache servers</strong>, ensuring broad coverage to{' '}
        <strong>reach all eyeballs</strong>, even in hard-to-reach areas.
      </Text>

      <div className={cx('tilesWrapper', 'mb-7')}>
        <AnimatedPath
          align="center"
          className={styles.path}
          delayMs={200}
          durationMs={500}
          height={380}
        />
        <Tiles className={styles.tiles}>
          {data.map(({ icon, title, value }) => (
            <div key={title} className={styles.item}>
              <Icon size={50}>{icon}</Icon>
              <div className={styles.itemContent}>
                <Text
                  className={styles.value}
                  color="gray900"
                  display="block"
                  size={34}
                  tag="strong"
                  weight="medium"
                >
                  {value}
                </Text>
                <Text className={styles.title} color="gray500" size={16} tag="p">
                  {title}
                </Text>
              </div>
            </div>
          ))}
        </Tiles>
      </div>

      <Text
        align="center"
        className={styles.mapTitle}
        color="gray900"
        display="block"
        size={30}
        tag="h3"
        weight="bold"
      >
        Global traffic distribution
      </Text>
      <ContinentStats />
    </Container>
  </Section>
);
